
import { defineComponent, reactive, ref, computed, watch } from 'vue'
import { ElMessage } from 'element-plus/lib/components'

import httpRequest from '@/service'
import BreadCrumb from '@/components/breadCrumb/index.vue'

export default defineComponent({
  name: 'MyCollect',
  components: { BreadCrumb },
  setup() {
    interface ITable {
      id?: string
      favor?: boolean
      [key: string]: any
    }
    const tableData = ref<ITable>([])
    const currentPage = ref(1) //当前页数
    const pageSize = ref(10) //页码大小
    const total = ref(0)
    const effTimeId = reactive<ITable>({
      effectId: '',
      timelinessId: ''
    })
    const activeName = ref('1')
    const loading = ref(false)
    const tableLabel = ref<any[]>([])
    const tableUrl = ref('')
    let cancelUrl = ''
    watch(
      activeName,
      (val) => {
        switch (val) {
          case '1':
            cancelUrl = 'cancelFeedback'
            tableUrl.value = 'myFeedbackTable'
            tableLabel.value = [
              { prop: 'index', label: '序号', width: 70 },
              { prop: 'lawName', label: '法律名称', align: 'left' },
              { prop: 'feedbackContent', label: '详细信息', align: 'left' },
              { prop: 'suggestName', label: '反馈页面', width: 200 },
              { prop: 'abnormalName', label: '反馈类型', width: 120 },
              { prop: 'stateS', label: '反馈状态', width: 80 },
              { prop: 'feedbackTime', label: '反馈时间', width: 120 }
            ]
        }
        getTableData()
      },
      { immediate: true }
    )
    const levelTypes = ref<ITable>([])
    const timeTypes = ref<ITable>([]) //时效性数据
    // 表格数据
    function getTableData() {
      loading.value = true
      httpRequest
        .post({
          url: tableUrl.value,
          params: {
            page: currentPage.value,
            pageSize: pageSize.value,
            userName: JSON.parse(localStorage.getItem('loginInfo') || '{}').user?.userName
          }
        })
        .then((res: any) => {
          tableData.value = res.data.list
          total.value = res.data.total
          loading.value = false
        })
        .catch((err) => {
          loading.value = false
          console.log(err)
        })
    }
    // 取消反馈
    const unCollectClick = (row) => {
      loading.value = true
      httpRequest
        .post({
          url: cancelUrl,
          params: {
            id: row.id
          }
        })
        .then(() => {
          ElMessage.success('已撤回反馈')
          getTableData()
        })
        .catch((err) => {
          console.log(err)
          loading.value = false
        })
    }
    // 当前页数
    const handleCurrentChange = (val: number) => {
      currentPage.value = val
      getTableData()
    }
    // 改变页码大小
    const handleSizeChange = (val: number) => {
      pageSize.value = val
      getTableData()
    }
    return {
      total,
      tableData,
      tableLabel,
      levelTypes,
      timeTypes,
      currentPage,
      effTimeId,
      pageSize,
      unCollectClick,
      handleCurrentChange,
      handleSizeChange,
      activeName,
      loading
    }
  }
})
